import axios from "../http-common";
import loggerService from "./logger";
import authentication from "../authentication";

export default {
    fetchLangages(payLoad) {
        return axios
            .get(
                `${
              process.env.VUE_APP_API_URL
            }/FormulationMapping/GetCountriesAndLanguagesByUser/${authentication.getUserEmail()}/${
              payLoad.cucCodePrimary
            }/${payLoad.revisionPrimary}/${
                payLoad.langageType
            }`
            )
            .catch((error) => {
                loggerService.logError(error);
            });
    },
    archiveOrRenewFormulation(setData) {
        return axios
            .post(
                `${process.env.VUE_APP_API_URL}/FormulationMapping/ArchiveOrRenewFormulation`,
                setData
            )
            .catch((error) => {
                loggerService.logError(error);
            });
    }
}