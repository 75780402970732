var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.isLoader
        ? _c("Loader", { attrs: { loading: _vm.isLoader } })
        : _c("div", { staticClass: "editoptions" }, [
            _c(
              "div",
              { staticClass: "p-2 mb-4 bg-gray-300 info flex items-center" },
              [
                _c(
                  "svg",
                  {
                    attrs: {
                      xmlns: "http://www.w3.org/2000/svg",
                      viewBox: "0 0 20 20"
                    }
                  },
                  [
                    _c("path", {
                      attrs: {
                        d:
                          "M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zM9 11v4h2V9H9v2zm0-6v2h2V5H9z"
                      }
                    })
                  ]
                ),
                _vm.isLanguageType === "archive"
                  ? _c("div", [
                      _c("p", [
                        _vm._v(
                          " Disabled checkbox means the selected CUC code is already mapped to a product. "
                        )
                      ]),
                      _c("p", [
                        _vm._v(
                          " Checked and Disabled Checkbox means the selected CUC Code is already archived "
                        )
                      ])
                    ])
                  : _vm._e(),
                _vm.isLanguageType === "renew"
                  ? _c("div", [
                      _vm._v(
                        " Enabled checkbox means the selected CUC code is already archived and can be renewed "
                      )
                    ])
                  : _vm._e()
              ]
            ),
            !_vm.isLoader
              ? _c("table", { staticClass: "pioti-table table-auto" }, [
                  _c("thead", { staticClass: "language-table" }, [
                    _c("tr", [
                      _c("th", [_vm._v("Country")]),
                      _c("th", [_vm._v("Language")])
                    ])
                  ]),
                  _c("tbody", [
                    _c("tr", [
                      _c("td", { attrs: { colspan: "2" } }, [
                        _c(
                          "div",
                          { staticClass: "data-wrapper" },
                          _vm._l(_vm.countryCategoryData, function(
                            country,
                            qIndex
                          ) {
                            return _c(
                              "div",
                              {
                                key: "a" + qIndex,
                                staticClass: "editoptions__country"
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "options",
                                    on: {
                                      mousedown: function($event) {
                                        return _vm.onCheckboxChange(
                                          country,
                                          "country"
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c("Checkbox", {
                                      attrs: {
                                        checked: country.Checked,
                                        disabled: country.Disabled,
                                        label: country.Country
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm._l(country.Languages, function(
                                  language,
                                  cIndex
                                ) {
                                  return _c(
                                    "div",
                                    {
                                      key: "b" + cIndex,
                                      staticClass: "options",
                                      attrs: { disabled: language.Disabled },
                                      on: {
                                        mousedown: function($event) {
                                          return _vm.onCheckboxChange(
                                            language,
                                            "language"
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c("Checkbox", {
                                        attrs: {
                                          checked: language.Checked,
                                          label: language.Language,
                                          disabled: language.Disabled
                                        }
                                      })
                                    ],
                                    1
                                  )
                                }),
                                _vm._l(
                                  _vm.maxCount - country.Languages.length,
                                  function(n, index) {
                                    return _c("div", {
                                      key: "c" + index,
                                      staticClass: "options"
                                    })
                                  }
                                )
                              ],
                              2
                            )
                          }),
                          0
                        )
                      ])
                    ])
                  ])
                ])
              : _vm._e(),
            _c(
              "div",
              { staticClass: "popup-action__button" },
              [
                !_vm.isLoader
                  ? _c(
                      "Button",
                      {
                        attrs: { disabled: _vm.isOk },
                        on: {
                          click: function($event) {
                            return _vm.save()
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.btnText))]
                    )
                  : _vm._e()
              ],
              1
            )
          ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }