<template>
  <div>
    <Loader :loading="isLoader" v-if="isLoader" />
    <div class="editoptions" v-else>
      <div class="p-2 mb-4 bg-gray-300 info flex items-center">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
          <path
            d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zM9 11v4h2V9H9v2zm0-6v2h2V5H9z"
          />
        </svg>
        <div v-if="isLanguageType === 'archive'">
          <p>
            Disabled checkbox means the selected CUC code is already mapped to a
            product.
          </p>
          <p>
            Checked and Disabled Checkbox means the selected CUC Code is already
            archived
          </p>
        </div>
        <div v-if="isLanguageType === 'renew'">
          Enabled checkbox means the selected CUC code is already archived and
          can be renewed
        </div>
      </div>
      <table v-if="!isLoader" class="pioti-table table-auto">
        <thead class="language-table">
          <tr>
            <th>Country</th>
            <th>Language</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colspan="2">
              <div class="data-wrapper">
                <div
                  class="editoptions__country"
                  v-for="(country, qIndex) in countryCategoryData"
                  :key="'a' + qIndex"
                >
                  <div
                    class="options"
                    @mousedown="onCheckboxChange(country, 'country')"
                  >
                    <Checkbox
                      :checked="country.Checked"
                      :disabled="country.Disabled"
                      :label="country.Country"
                    />
                  </div>
                  <div
                    class="options"
                    v-for="(language, cIndex) in country.Languages"
                    :key="'b' + cIndex"
                    :disabled="language.Disabled"
                    @mousedown="onCheckboxChange(language, 'language')"
                  >
                    <Checkbox
                      :checked="language.Checked"
                      :label="language.Language"
                      :disabled="language.Disabled"
                    />
                  </div>
                  <!--extrabox-->
                  <div
                    class="options"
                    v-for="(n, index) in maxCount - country.Languages.length"
                    :key="'c' + index"
                  ></div>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="popup-action__button">
        <Button @click="save()" v-if="!isLoader" :disabled="isOk">{{
          btnText
        }}</Button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import authentication from "../../authentication";
import languageService from "../../services/languages";
import loggerService from "../../services/logger";

export default {
  components: {
    Checkbox: () => import(/* webpackPrefetch: true */ "./Checkbox"),
    Loader: () => import(/* webpackPrefetch: true */ "./Loader"),
    Button: () => import(/* webpackPrefetch: true */ "./Button"),
  },
  data: () => ({
    countryCategoryData: [],
    isLoader: true,
    btnText: "",
  }),
  created() {
    setTimeout(() => {
      this.isLoader = false;
    }, 500);
    this.getAll();
    this.btnText = this.isLanguageType === "archive" ? "Archive" : "Renew";
  },
  computed: {
    ...mapGetters({
      isLanguageType: "GET_IS_LANGUAGE_TYPE",
      cucCodePrimary: "GET_CUC_CODE_PRIMARY",
      revisionPrimary: "GET_REVISION_PRIMARY",
    }),
    maxCount() {
      let count = 0;
      this.countryCategoryData.map((country) => {
        if (count < country.Languages.length) count = country.Languages.length;
      });
      return count;
    },
    isOk() {
      let btnProp = true;
      if (
        this.countryCategoryData === null ||
        this.countryCategoryData === [] ||
        this.countryCategoryData === undefined
      ) {
        return;
      } else {
        this.countryCategoryData.forEach((item) => {
          item.Languages.some((item1) => {
            if (item1.Checked == true && item1.Disabled == false) {
              btnProp = false;
            }
          });
        });
      }
      return btnProp;
    },
  },
  methods: {
    onCheckboxChange(obj, type) {
      if (!obj.Disabled) {
        obj.Checked = obj.Checked ? false : true;
      }
      this.generateLanguages(obj, type);
    },
    handleMousedown(obj) {
      if (this.$el.contains(obj.target)) {
        obj.Checked = false;
      }
    },
    generateLanguages(obj, type) {
      let lanaguageCheckedCount = 0;
      this.countryCategoryData.map(function (country) {
        if (type == "country" && obj.Country == country.Country) {
          // if country checked, select all enabled languages
          country.Languages.map(function (language) {
            if (!language.Disabled) language.Checked = country.Checked;
          });
        } else if (type == "language") {
          lanaguageCheckedCount = 0;
          country.Languages.map(function (language) {
            // if all lanaguages are selected for a country, select the country
            if (language.Checked == false) lanaguageCheckedCount++;
          });
          if (country.Languages.length == lanaguageCheckedCount)
            country.Checked = false;
          else country.Checked = true;
        }
      });
    },
    async save() {
      const setData = {
        EmailId: authentication.getUserEmail(),
        Action: "",
        CucCode: this.cucCodePrimary,
        Revision: this.revisionPrimary.Value,
        countryMappings: this.countryCategoryData,
      };
      if (this.isLanguageType === "archive") {
        setData.Action = "Archive";
      } else {
        setData.Action = "Renew";
      }

      try {
        const { data } = await languageService.archiveOrRenewFormulation(
          setData
        );

        loggerService.logSuccess(data);

        this.$emit("on-close");
      } catch (error) {
        loggerService.logError(error);
      }
    },
    async getAll() {
      try {
        const payLoad = {
          cucCodePrimary: this.cucCodePrimary,
          revisionPrimary: this.revisionPrimary.Value,
          langageType: this.isLanguageType === "renew" ? "Renew" : "Archive",
        };
        const { data } = await languageService.fetchLangages(payLoad);
        this.countryCategoryData = data;
      } catch (error) {
        loggerService.logError(error);
        this.countryCategoryData = [];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
table {
  tr {
    background: #cbe4fc;
    th {
      padding: 5px;
      text-align: left;
      &:nth-child(2) {
        position: absolute;
        left: 200px;
      }
    }
    td {
      padding: 0 !important;
      .data-wrapper {
        max-height: 200px;
        min-height: 50px;
        overflow-y: auto;
      }
    }
  }
}
.editoptions {
  border-bottom: 0;
  border-right: 0;
  &__country {
    display: flex;
    flex-direction: row;
    flex-flow: nowrap;
  }
  .options {
    max-width: 100px;
    min-width: 100px;
    //border: 1px solid #d3d0d0;
    padding: 5px 5px 5px 5px;
    &:nth-child(1) {
      max-width: 200px;
      min-width: 200px;
    }
  }
  &__country {
    padding: 5px 0;
    &:nth-child(even) {
      background-color: #f4faff;
    }
    &:nth-child(odd) {
      background-color: #fff;
    }
    &:hover {
      cursor: default;
      background-color: #eee !important;
    }
  }
  .info {
    color: #444;
    svg {
      height: 15px;
      fill: #444;
      margin-right: 10px;
    }
  }
}
</style>
